
import { Options, Vue } from 'vue-class-component'
import AdminProducts from '@/components/Products/AdminProducts/index.vue' // @ is an alias to /src

@Options({
  components: {
    AdminProducts
  }
})
export default class ProductsView extends Vue {}
