export enum OrderStatusType {
  Afventer = <any>'afventer',
  Godkendt = <any>'godkendt',
  Afvist = <any>'afvist',
  Annulleret = <any>'annulleret'
}

export enum SysClubOrderWheelchairFriendlyType {
  Ja = <any>'Ja',
  Nej = <any>'Nej'
}

export enum SharePermissionType {
  Nej = <any>'nej',
  Telefonnummer = <any>'telefonnummer',
  Email = <any>'email',
  Begge = <any>'begge'
}

export interface SysOrderStorage {
  orderId: null
  clubPurchase: boolean
  timeStamp: number
  clubId: number
}

export interface SysOrderUnitId {
  produkt_id: number
  ordreenheder_antal: number
  ordreenheder_beskrivelse: string
  ordreenheder_pris: number
  created_at: string
  updated_at: string
}

export interface SysClubOrderId {
  id: null
  klubber_status: boolean
  klubber_klubnavn: string
  klubber_logo: string
  klubber_vejnavn: string
  bypost_id: number
  region_id: number
  klubber_telefonnummer: string
  klubber_email: string
  klubber_saldo: number
  klubber_klubbeskrivelse: string
  klubber_aaben: string
  klubber_baner: number
  klubber_hjemmekampe: number
  klubber_etableringsaar: string
  klubber_koerestolsvenlig: SysClubOrderWheelchairFriendlyType
  klubber_ansvarlig_id: number
  klubber_slug: string
  klubber_kontingent_slut: string
}

export interface SysOrderId {
  id: null
  ordre_fakturanummer: number
  // bruger_id: number
  user_id: number
  ordre_kommentar: string
  ordre_status: OrderStatusType
  ordre_transaktion: number
  created_at: string
  updated_at: string
  ordre_navn: string
  ordre_adresse: string
  ordre_postnummer: string
  ordre_by: string
  ordre_telefon: string
  ordre_email: string
  ordre_total: string
  ordre_land: string
  ordre_faktura: any[]
}

export interface SysOrderUnitProductId {
  id: null
  produkt_status: boolean
  produkt_navn: string
  produkt_pris: number
  produkt_datofra: string
  produkt_datotil: string
  produkt_beskrivelse: string
  // bruger_id: number
  user_id: number
  produkt_type_id: number
  created_at: string
  updated_at: string
}

export interface SysOrderUserId {
  id: null
  blocked: boolean
  username: string
  email: string
  firstname: string
  lastname: string
  phone: number
  lastActive: string
  resetPasswordToken: string
  brugergruppe: string
  usrgroup: number
  created_at: string
  updated_at: string
}

/* export interface SysOrderUserId {
  id: null
  bruger_status: boolean
  bruger_brugernavn: string
  bruger_email: string
  bruger_fornavn: string
  bruger_efternavn: string
  bruger_telefon: string
  bruger_sidste_login: string
  published_at: string
  updated_at: string
  bruger_token: string
  brugergruppe: string
  bruger_brugergruppe_id: number
} */

export interface SysOrderUnit {
  id: null
  produkt_id: SysOrderUnitProductId
  ordreenheder_antal: number
  ordreenheder_beskrivelse: string
  ordreenheder_pris: number
  created_at: string
  updated_at: string
}

export interface SysOrderExtra {
  memberUserName: string
  memberFirstName: string
  memberLastName: string
  memberEmail: string
  memberTelephone: number
  memberAddress: string
  memberPassword: string
  memberGenderValueId: number
  memberBirthDate: string
  cityZipId: number
  clubId: number
  sharePermissionType: SharePermissionType
}

export default interface SysOrder {
  id: null
  ordre_fakturanummer: number
  // bruger_id: SysOrderUserId
  user_id: SysOrderUserId
  ordre_kommentar: string
  ordre_status: OrderStatusType
  ordre_transaktion: number
  ordreenheders_id: SysOrderUnitId[]
  ordre_faktura: any[]
  ordre_navn: string
  ordre_adresse: string
  ordre_postnummer: string
  ordre_by: string
  ordre_telefon: string
  ordre_email: string
  ordre_land: string
  ordre_total: number
  ordre_ekstra: SysOrderExtra
  created_at: string
  updated_at: string
}

export interface SysClubOrders {
  klubber_id: SysClubOrderId
  ordre_id: SysOrderId
  created_at: string
  updated_at: string
}
