export enum AccountLSType {
  ls1 = <any>'ls1',
  ls2 = <any>'ls2',
  ls3 = <any>'ls3'
}

export interface SysAccount {
  id: null
  konti_nummer: number
  konti_navn: string
  konto_ls: AccountLSType
  created_at: string
  updated_at: string
}

export interface SysProductUser {
  id: null
  blocked: boolean
  username: string
  email: string
  firstname: string
  lastname: string
  phone: number
  bruger_sidste_login: string
  resetPasswordToken: string
  usrgroup: number
  created_at: string
  updated_at: string
}

/* export interface SysProductUser {
  id: null
  bruger_status: boolean
  bruger_brugernavn: string
  bruger_email: string
  bruger_fornavn: string
  bruger_efternavn: string
  bruger_telefon: string
  bruger_sidste_login: string
  published_at: string
  created_at: string
  updated_at: string
  bruger_token: string
  bruger_brugergruppe_id: number
} */

export interface SysProductProductTypeId {
  id: null
  produkt_type_status: boolean
  produkt_type_navn: string
  produkt_type_kontofra1: string
  produkt_type_kontofra2: string
  produkt_type_kontofra3: string
  produkt_type_kontotil: string
  produkt_type_beskrivelse: string
  produkt_type_unikt: boolean
  created_at: string
  updated_at: string
  konti_fra1: number
  konti_fra2: number
  konti_fra3: number
  konti_til: number
}

export default interface SysProduct {
  id: null
  produkt_status: boolean
  produkt_navn: string
  produkt_pris: number
  produkt_datofra: string
  produkt_datotil: string
  produkt_beskrivelse: string
  // bruger_id: SysProductUser
  user_id: SysProductUser
  produkt_type_id: SysProductProductTypeId
  created_at: string
  updated_at: string
}

export interface SysProductId {
  id: null
  produkt_status: boolean
  produkt_navn: string
  produkt_pris: number
  produkt_datofra: string
  produkt_datotil: string
  produkt_beskrivelse: string
  // bruger_id: number
  user_id: number
  created_at: string
  updated_at: string
}

export interface SysProductProductType {
  id: null
  produkt_type_status: boolean
  produkt_type_navn: string
  produkt_type_kontofra1: string
  produkt_type_kontofra2: string
  produkt_type_kontofra3: string
  produkt_type_kontotil: string
  produkt_type_beskrivelse: string
  produkt_type_unikt: boolean
  produkt_id: SysProductId
  created_at: string
  updated_at: string
  konti_fra1: SysAccount | null
  konti_fra2: SysAccount | null
  konti_fra3: SysAccount | null
  konti_til: SysAccount | null
}
